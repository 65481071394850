import React, { Component } from "react";
import { Collapse, Nav, NavItem, NavLink, Button, TabContent, TabPane } from "reactstrap";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import BankTransactionsListContainer from '../../components/bank-transactions-list-container/BankTransactionsListContainer'
import TransferenceListContainer from '../../components/transference-list-container/TransferenceListContainer'
import BankTransactionsListFilters from '../../components/bank-transactions-list-filter/BankTransactionsListFilters'
import TransferenceListFilter from '../../components/transference-list-filter/TransferenceListFilter'
import { TransferenceStatus} from 'operation-lib-model-notary-platform';
import BankTransactionsFund from "../../components/bank-transactions-list/BankTransactionsFund";
import BackendApiService from "../../services/BackendApiService";



import FilterIcon from "../../theme/assets/filter_conces.svg";

const tabsDefinition = {
  transactions: {
    id: 'transactions',
    title: 'Saldo disponible en la cuenta',
    component: BankTransactionsListContainer,
    filterComponent:BankTransactionsListFilters,
    filters: {}
  },
  transfer_tax:{
    id: 'transfer_tax',
    title: 'Contratos pendientes de pago de impuesto',
    filterComponent:TransferenceListFilter,
    filters: {
      STATUS: TransferenceStatus.TAX.id,
      DATE_IN_STATUS: true,
      includeTax: true
    },
    component: TransferenceListContainer
  },
  transfer_mangament:{
    id: 'transfer_mangament',
    title: 'Contratos pendientes en estado gestión',
    filterComponent:TransferenceListFilter,
    filters: {
      STATUS: TransferenceStatus.MANAGEMENT.id,
      DATE_IN_STATUS: true,
      includeTax: true
    },
    component: TransferenceListContainer
  }
};

class InvoiceCenterBilling extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accounts:[],
      accountSelected:null,
      loadedError:null,
      loaded:false,
      filters: {},
      userData: props.userData,
      activeTab: tabsDefinition.transactions.id,
      filtersCollapsed: true,
      listFilters: {
        transactions: Object.assign({}, tabsDefinition.transactions.filters),
        transfer_tax: Object.assign({}, tabsDefinition.transfer_tax.filters),
        transfer_mangament: Object.assign({}, tabsDefinition.transfer_mangament.filters),
      },
      recordCount: {
        transactions: 0,
        transfer_tax: 0,
        transfer_mangament:0
      }
    };
    this.toggleFilters = this.toggleFilters.bind(this);
    this.setFiltersCallback = this.setFiltersCallback.bind(this);
    this.updateAccountsState = this.updateAccountsState.bind(this);
    this.loadAccounts = this.loadAccounts.bind(this);
    this.currentTableRef = React.createRef();
    this.filterComponentRef = React.createRef();
    this.summaryComponentRef = React.createRef();
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      const listFilters = this.state.listFilters;
      listFilters[tab] = tabsDefinition[tab].filters;
      this.setState({
        activeTab: tab,
        listFilters,
        filtersCollapsed: true
      });
      if(this.filterComponentRef.current)
        this.filterComponentRef.current.resetFilters();
    }
  }
  componentDidMount() {
    const recordCount = {};
    Object.keys(tabsDefinition).forEach(tabKey => {
      recordCount[tabKey] = 0;
    });
    this.loadAccounts();
    this.setState({recordCount});
  }

  async loadAccounts() {
    const companyId = this.state.userData.afpr_us_data.idgrupo_empresa;
    try {
      const costCenters = await BackendApiService.getCostCenters(companyId);
      if (costCenters.data.length > 0) {
        const costCenterIds = costCenters.data.map(costCenter=> {
          return costCenter.id
        });
        try {
          const accounts = await BackendApiService.getAccountActivesWithCostCenters(costCenterIds.join(','));
          if (accounts.data.length > 0) {
            this.setState({
              accounts: accounts.data,
              accountSelected: accounts.data[0] || null,
              loadedError: false,
              loaded: true
            });
          } else {
            this.setState({loadedError: "El concesionario no tiene cuentas activas"})
          }
        } catch (error) {
          this.setState({loadedError: "No se obtuvieron cuentas"})
          console.log(error);
        }
      } else {
        this.setState({loadedError: "No se obtuvieron centros de costo"})
      }
    } catch (error) {
      this.setState({loadedError: "No se obtuvieron centros de costo"})
      console.log(error);
    }
  }

  toggleFilters() {
    this.setState({filtersCollapsed: !this.state.filtersCollapsed});
  }

  buildFilters(tab, filters = null) {
    const where = filters || this.state.listFilters[tab];
    where.COMPANY_ID = this.state.userData.afpr_us_data.idgrupo_empresa;
    return {where: JSON.stringify(where)};
  }

  setFiltersCallback(filters) {
    const tab = this.state.activeTab;
    const listFilters = this.state.listFilters;
    for (const filterKey in tabsDefinition[tab].filters) {
      if (tabsDefinition[tab].filters.hasOwnProperty(filterKey)) {
        if (filters.hasOwnProperty(filterKey)) {
          const filterValue = tabsDefinition[tab].filters[filterKey].toString().split(',').filter(filterValue => filterValue.toString() === filters[filterKey].toString());
          filters[filterKey] = filterValue.join(',');
        }
        else {
          filters[filterKey] = tabsDefinition[tab].filters[filterKey];
        }
      }
    }
    listFilters[tab] = filters;
    this.setState({listFilters});
    this.currentTableRef.current.refreshFilters(this.buildFilters(tab, listFilters[tab]));
  }
  updateAccountsState(accountSelected){
    this.setState({
      accountSelected: accountSelected
    })
  }
  render() {
    const activeTab = this.state.activeTab;
    const currentTabObject = tabsDefinition[activeTab];

    return (
      <div>
        {this.state.userData.afpr_us_data ? (
          <div>
            <div className="page-title">
              <FontAwesomeIcon
                icon="file-invoice-dollar"
                className="icon-title"
                alt="Icono de Cierres"
              />
              <h2 className="text-title">Facturación</h2>
            </div>
            {this.state.accounts.length > 0 && <BankTransactionsFund
                accountSelected={this.state.accountSelected}
                accounts={this.state.accounts}
                changeAccount={this.updateAccountsState}
            />}
            <Nav tabs className="tabs-ligth">
              {Object.keys(tabsDefinition).map((key, index) => {
                const tab = tabsDefinition[key];
                return (
                    <NavItem key={`tab-nav-item-${index}`}>
                      <NavLink
                          key={`tab-nav-${index}`}
                          className={activeTab === tab.id ? 'active' : ''}
                          onClick={() => this.toggleTab(tab.id)}
                      >
                        <div className="tab-title">{tab.title}</div>
                        <div className="tab-subtitle">{this.state.recordCount[tab.id]}</div>
                      </NavLink>
                    </NavItem>
                );
              })}
              <NavItem className="nav-button">
                <Button className="btn btn-sm btn-primary-dark" onClick={this.toggleFilters}>
                  <img className="filter-icon" src={FilterIcon} alt="Filter Icon"/> Filtros
                </Button>
              </NavItem>
            </Nav>
            <Collapse isOpen={!this.state.filtersCollapsed}>
              <div className="filter-form-wrapper">

                  <currentTabObject.filterComponent
                      ref={this.filterComponentRef}
                      setFiltersCallback={this.setFiltersCallback}
                      tab={this.state.activeTab}
                  />
              </div>
            </Collapse>
            <TabContent activeTab={activeTab}>
              {Object.keys(tabsDefinition).map((key, index) => {
                const tab = tabsDefinition[key];
                return (
                    <TabPane tabId={tab.id} key={`tab-pane-${index}`}>
                      {activeTab === tab.id?
                          <tab.component
                              id={tab.id}
                              tab={tab.id}
                              ref={this.currentTableRef}
                              key={`tab-list-${index}`}
                              userData={this.state.userData}
                              updateCount={count => {
                                            const recordCountState = this.state.recordCount;
                                            recordCountState[tab.id] = count;
                                            this.setState({recordCount: recordCountState});
                                        }}
                              accountSelected={this.state.accountSelected}
                              loadedAccountsError={this.state.loadedError}
                              loadedAccounts={this.state.loaded}

                              filters={this.buildFilters(tab.id)}
                          />
                          :
                          <div key={`tab-loading-${index}`}>Cargando</div>
                      }
                    </TabPane>
                );
              })}
            </TabContent>
          </div>
        ) : (
          <Redirect to="/login" />
        )}
      </div>
    );
  }
}

export default connect(store => ({
  userData: store.app.userData
}))(InvoiceCenterBilling);
