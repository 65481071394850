const TRANSFERENCE_TYPE_MAPPING = {
    '0': 'AC',
    '1': 'AV',
    '2': 'CA',
    '3': 'RE',
    '6': 'ANPRO',
    '8': 'ANPRE'
};

export default {
    TRANSFERENCE_TYPE_MAPPING,
    codeToString(typeCode){
        return TRANSFERENCE_TYPE_MAPPING[typeCode];
    }
};
