import React, {Component} from 'react';
import {Row, Col, FormGroup, Input, Button, Form} from 'reactstrap';
import { TransferenceType} from 'operation-lib-model-notary-platform';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';

const FORMAT = 'YYYY-MM-DD';

class TransferenceListFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formFilters: {
                STATUS_TIMESTAMP: '',
                TRANSFER_TYPE: '',
                PLATE:'',
                AMMOUNT_TAX:'',
                AMMOUNT_FULL:'',
                PRIORITY:''
            }
        };

        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.setFilters = this.setFilters.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
    }

    handleFilterChange(event) {
        let newFilterData = {};
        newFilterData[event.target.id] = event.target.value;
        newFilterData = Object.assign(this.state.formFilters, newFilterData);
        this.setState({formFilters: newFilterData});
    }

    handleDayChange(propName, selectedDay, modifiers, dayPickerInput) {
        const input = dayPickerInput.getInput();
        this.handleFilterChange({
            target:{
                id: propName,
                value:input.value.trim()
            }
        })
    }
    
    setFilters() {
        const filters = {};
        for (const filter in this.state.formFilters) {
            if (this.state.formFilters.hasOwnProperty(filter) && this.state.formFilters[filter]) {
                filters[filter] = this.state.formFilters[filter];
            }
        }
        this.props.setFiltersCallback(filters);
    }

    resetFilters() {
        const filters = {
            STATUS_TIMESTAMP: '',
            TRANSFER_TYPE: '',
            PLATE:'',
            AMMOUNT_TAX:'',
            AMMOUNT_FULL:'',
            PRIORITY:''
        };
        this.props.setFiltersCallback({});
        this.setState({formFilters: filters});
    }

    render() {
        const transferenceTypeListArray = Object.keys(TransferenceType);
        return (
            <Form className={'px-2'}>
                <Row>
                    <Col md={3}>
                        <FormGroup>
                            <DayPickerInput
                                onDayChange={(selectedDay, modifiers, dayPickerInput)=>{this.handleDayChange('STATUS_TIMESTAMP',selectedDay, modifiers, dayPickerInput)}}
                                formatDate={formatDate}
                                format={FORMAT}
                                value={this.state.formFilters.STATUS_TIMESTAMP}
                                parseDate={parseDate}
                                inputProps={{
                                    className:'form-control form-control-sm',
                                    placeholder:'Fecha estado'
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Input type="select" id="TRANSFER_TYPE" className="form-control-sm"
                                   onChange={this.handleFilterChange}
                                   value={this.state.formFilters.TRANSFER_TYPE}>
                                <option value="">Tipo de contrato</option>
                                {transferenceTypeListArray.map(key=>{
                                    return <option key={key} value={TransferenceType[key].id}>{TransferenceType[key].shortLabel}</option>
                                })}

                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Input type="text"
                                   id="PLATE"
                                   className="form-control-sm"
                                   placeholder="Patente"
                                   onChange={this.handleFilterChange}
                                   value={this.state.formFilters.PLATE}>
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <FormGroup>
                            <Input type="text"
                                   id="AMMOUNT_TAX"
                                   className="form-control-sm"
                                   placeholder="Monto impuesto"
                                   onChange={this.handleFilterChange}
                                   value={this.state.formFilters.AMMOUNT_TAX}>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Input type="text"
                                   id="AMMOUNT_FULL"
                                   className="form-control-sm"
                                   placeholder="Monto total"
                                   onChange={this.handleFilterChange}
                                   value={this.state.formFilters.AMMOUNT_FULL}>
                            </Input>
                        </FormGroup>
                    </Col>

                    <Col md={3}>
                        <FormGroup>
                            <Input type="select" id="PRIORITY" className="form-control-sm"
                                   onChange={this.handleFilterChange}
                                   value={this.state.formFilters.PRIORITY}>
                                <option value="">Prioridad</option>
                                <option value="1">Si</option>
                                <option value="0">No</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    
                    <Col md={2} className="ml-auto filter-button">
                        <Button className="btn btn-sm btn-primary-darker btn btn-secondary mr-2" onClick={this.setFilters}>Aplicar</Button>
                        <Button className="btn btn-sm btn-primary btn" onClick={this.resetFilters}>Limpiar</Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default TransferenceListFilter;
